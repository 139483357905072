import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Categoriesgrid = (props) => {
  const { t } = useTranslation();

  const postsJSX = props.posts.map((post) => (
    <div
      className="col-lg-5 col-md-12 categories-blog m-lg-4 mb-md-3  m-3"
      key={post.id}
      onClick={() => `/blog-details/${post.id}`}
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/blog/${post.img})`,
      }}
    >
      <Link to={`/blog-grid/${post.id}`}>
        <h3>{post.title}</h3>
      </Link>
    </div>
  ));

  return (
    <section className="service-two categories-content">
      <div className="container  go-top pt-5 mt-5">
        <div className="row">
          <div className="col-lg-12 d-flex justify-content-center">
            <div className="my-auto">
              <div className="service-two__block">
                <div className="block-title-two text-center">
                  <h3 className="pb-3">{t("blog.title")}</h3>
                  <span className="text-center">{t("blog.subtitle")}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="posts row">{postsJSX}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Categoriesgrid;
