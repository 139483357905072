import React from "react";
import { useTranslation } from "react-i18next";

const CtaV14 = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const { t } = useTranslation();

  return (
    <section
      className="container cta-five"
      style={{ padding: "5rem 0 3rem 1rem" }}
    >
      <div
        className="d-flex flex-column flex-lg-row"
        style={{ gap: "0 2rem", alignItems: "center" }}
      >
        <div className="my-auto">
          <div className="cta-five__content">
            <h3>{t("home.section3.title")}</h3>
            <p>{t("home.section3.subtitle")}</p>
          </div>
        </div>
        <img
          src={publicUrl + "assets/images/resources/footer_img.png"}
          alt="Footer image"
          style={{
            width: "100%",
            maxWidth: "449px",
            objectFit: "contain",
          }}
        />
      </div>
    </section>
  );
};

export default CtaV14;
