import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Service = () => {
  const { t } = useTranslation();
  const escenarios = [
    {
      id: t("enviroments.covid.id"),
      title: t("enviroments.covid.title"),
      img: t("enviroments.covid.img"),
      active: t("enviroments.covid.active"),
    },
    {
      id: t("enviroments.urgencias.id"),
      title: t("enviroments.urgencias.title"),
      img: t("enviroments.urgencias.img"),
      active: t("enviroments.urgencias.active"),
    },
    {
      id: t("enviroments.uci.id"),
      title: t("enviroments.uci.title"),
      img: t("enviroments.uci.img"),
      active: t("enviroments.uci.active"),
    },
    {
      id: t("enviroments.pediatria.id"),
      title: t("enviroments.pediatria.title"),
      img: t("enviroments.pediatria.img"),
      active: t("enviroments.pediatria.active"),
    },
    {
      id: t("enviroments.hemodialisis.id"),
      title: t("enviroments.hemodialisis.title"),
      img: t("enviroments.hemodialisis.img"),
      active: t("enviroments.hemodialisis.active"),
    },
    {
      id: t("enviroments.seguridad.id"),
      title: t("enviroments.seguridad.title"),
      img: t("enviroments.seguridad.img"),
      active: t("enviroments.seguridad.active"),
    },
    {
      id: t("enviroments.cardiologia.id"),
      title: t("enviroments.cardiologia.title"),
      img: t("enviroments.cardiologia.img"),
      active: t("enviroments.cardiologia.active"),
    },
    {
      id: t("enviroments.neonatologia.id"),
      title: t("enviroments.neonatologia.title"),
      img: t("enviroments.neonatologia.img"),
      active: t("enviroments.neonatologia.active"),
    },
  ];

  const escenariosJS = escenarios.map((escenarios) => (
    <div className="col-md-3">
      <div
        className="service-two__single js-tilt"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/escenarios/${escenarios.img})`,
        }}
      >
        <Link to={escenarios.id ? `/service-details/${escenarios.id}` : "#"}>
          <div
            className="service-two__single-image"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/escenarios/${escenarios.img})`,
            }}
          />
          <div className="service-two__single-inner">
            {escenarios.active === false && (
              <span className="proximamente">
                {t("enviroments.development")}
              </span>
            )}
            <h3>
              <a to={`/service-details/${escenarios.id}`}>{escenarios.title}</a>
            </h3>
            <Link
              to={`/service-details/${escenarios.id}`}
              className="service-two__link mt-5"
            >
              <i className="fa fa-long-arrow-alt-right" />
            </Link>
          </div>
        </Link>
      </div>
    </div>
  ));
  return (
    <section className="service-two service-content">
      <div className="container go-top pt-4 mt-5">
        <div className="row">
          <div className="col-lg-12 d-flex justify-content-center">
            <div className="my-auto">
              <div className="service-two__block">
                <div className="block-title-two text-center">
                  <h3 className="pb-3">{t("environments.title")}</h3>
                  <span>{t("environments.subtitle")}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="service-two__box-wrap">
              <div className="escenarios row">{escenariosJS}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Service;
